<template>
  <div class="container">
    <el-page-header @back="goBack" content="日程详情"> </el-page-header>

    <div class="scheduleInfo">
      <div class="infoItem topic">{{ detailInfo.topic }}</div>
      <div class="infoItem">时间：{{ detailInfo.time }}</div>
      <div class="infoItem">学组：{{ detailInfo.content }}</div>
    </div>
    <div class="btns">
      <van-button type="primary" icon="video-o">观看直播</van-button>
      <van-button type="primary" icon="video-o">领取学分并观看</van-button>
    </div>
    <div class="scheduleTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="Time" label="时间"> </el-table-column>
        <el-table-column prop="Topic" label="讲题"> </el-table-column>
        <el-table-column label="任务">
          <template slot-scope="scope">
            <span>{{ scope.row.Identype | getIdentype }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <div class="DoctorName">{{ scope.row.DoctorName }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GetScheduleDetailById } from "@/api";

export default {
  name: "PCScheduleDetail",
  data() {
    return {
      scheduleId: this.$route.params.scheduleId || "",
      detailInfo: {
        topic: "冠心病影像",
        time: "08:30 - 10:30",
        content: "心血管学组",
      },
      tableData: [],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async GetScheduleDetailByIdAsync() {
      const r = await GetScheduleDetailById({
        ID: this.scheduleId,
      });
      if (r.code === 1) {
        // console.log(r.data);
        this.tableData = r.data.Meetings;
        this.detailInfo.content = r.data.Content;
        this.detailInfo.topic = r.data.GroupName;
        this.detailInfo.time = r.data.Time1;
      }
    },
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    // console.log(this.scheduleId);
    this.setTitle("日程详情");
    this.GetScheduleDetailByIdAsync();
  },
  filters: {
    getIdentype(v) {
      return v === 0 ? "主持" : "讲者";
    },
  },
};
</script>

<style scoped>
.container {
  padding: 15px;
  color: #444444;
  font-size: 14px;
}
.scheduleInfo .topic {
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 10px;
  margin-top: 20px;
}
.btns {
  margin: 20px auto;
  text-align: center;
  display: flex;
  align-items: center;
}
.btns >>> .van-button {
  width: 100%;
  background: #e3b965;
  font-size: 18px;
  border: 1px solid #fff;
}
.el-table {
  font-weight: 400;
  font-size: 12px;
  color: #444444;
}
.scheduleTable >>> thead tr th {
  background-color: #b00000;
  color: #fff;
  font-size: 14px;
}
.DoctorName {
  color: #c10000;
}
</style>